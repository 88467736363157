var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"pageTable"}},[_c('v-container',{attrs:{"xl":"","fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('data-table',{ref:"tabla",attrs:{"tableName":'Tipos de incapacidades',"columns":_vm.columns,"items":_vm.tableData.data,"paginationInfo":_vm.tableData.paginatorInfo,"showAdvancedFilters":false,"perPage":[10,25,50,100],"loading":_vm.isLoading},on:{"pagination":_vm.getPaginationInfo},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var data = ref.data;
return _c('tbody',{},_vm._l((data),function(item){return _c('tr',{key:item.id},[_c('td',[_c('div',[_vm._v(_vm._s(item.nombre))])]),_c('td',[_c('div',{staticClass:"tblOpciones"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.abrirModal('update', item)}}},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotones"},[_vm._v("edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonHover",attrs:{"icon":"","fab":"","dark":"","small":""},on:{"click":function($event){return _vm.eliminar(item)}}},on),[_c('v-icon',{staticClass:"tamIconoBoton invertirColorBotonesDelete"},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)])])}),0)}}])},[_c('template',{slot:"actionButtonsLeft"},[_c('div',{staticClass:"botonAgregar"},[_c('v-btn',{staticClass:"btnAdd",attrs:{"rounded":"","color":"#004BAF","right":""},on:{"click":function($event){return _vm.abrirModal('add')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v("add")]),_vm._v("Agregar "),_c('div',{staticClass:"mr-4"})],1)],1)])],2)],1)],1),[_c('v-dialog',{staticClass:"borde-card",attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"botonModal",attrs:{"id":"btnModal"}},on))]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"borde-card"},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card-title',{staticClass:"justify-center"},[_c('div',{staticClass:"headerModal"},[_c('img',{staticClass:"imgModal",attrs:{"src":_vm.imagen}}),_c('h2',{staticClass:"titleModal"},[_vm._v(_vm._s(_vm.tituloModal))])])]),_c('v-card-text',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isLoadingModal),expression:"isLoadingModal"}],attrs:{"id":"padre"}},[_c('div',{attrs:{"id":"loading"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":100,"width":7,"color":"blue"}})],1)]),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isLoadingModal),expression:"!isLoadingModal"}],attrs:{"grid-list-md":"","id":"contenedor"}},[_c('v-divider',{staticClass:"divider"}),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0 ma-0",attrs:{"cols":"12","xs":"12","sm":"12","md":"12"}},[_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"pa-0 ma-0",attrs:{"outlined":"","label":"Nombre","error-messages":errors,"required":""},model:{value:(_vm.incapacidad_tipo.nombre),callback:function ($$v) {_vm.$set(_vm.incapacidad_tipo, "nombre", $$v)},expression:"incapacidad_tipo.nombre"}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{staticClass:"justify-center"},[_c('div',{staticClass:"btnModal"},[_c('v-btn',{staticClass:"btnGuardar modal-pull-right",attrs:{"disabled":invalid,"loading":_vm.isSaving},on:{"click":function($event){return _vm.guardar()}}},[_vm._v("Guardar")]),_c('button',{staticClass:"btnCerrar modal-pull-right",attrs:{"disabled":_vm.isSaving},on:{"click":function($event){return _vm.cerrarModal()}}},[_vm._v("Cerrar")])],1)])],1)],1)]}}])})],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }